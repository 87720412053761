@charset "utf-8";

/* **************************
    変数
************************** */
// color
$main_color: #ee7800;
$second_color: #f29d4c;
$thrid_color: #f7bd8c;
$text_color: #464646;

$trans_speed_quick: .1s;
$trans_speed_normal: .3s;
$trans_speed_slow: 3s;

/* **************************
 　　共通
************************** */
html,body {
	width:100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: #464646;
	font-size:15px;
	line-height: 1.8em;

}
a {text-decoration: none;color:#444444;}
a:hover { color:#666666;  text-decoration: none;}
a:hover img { filter:alpha(opacity=50); -moz-opacity:0.50; opacity:0.50;text-decoration: none;}

.clear {clear: both;}

.txt01{font-size:30px;font-weight: bold;}
.txt02{font-weight: bold;}
.txt03{font-size:33px;font-weight: bold;margin-bottom:15px;}
.txt04{font-size: 18.75px; font-weight: bold;}
.txt-right{text-align:right;}
.txt-bold{font-weight: bold;}

#wrapper{width:100%;}


/* **************************
 　　ヘッダー
************************** */

#wrap01{
	width:100%;
	margin:0 auto;
	background:#fff;
	z-index:10;
	animation: fadeIn 4s ease 0s 1 normal;
	-webkit-animation: fadeIn 4s ease 0s 1 normal;
}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.header{
	position:fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 13px 15px;
	box-sizing: border-box;
	&-logo{
		width: 198.5px;
		display: block;
	}
	&-title{
		font-size: 12.5px;
		font-weight: lighter;
	}
	&-right{
		padding-bottom: 8px;
	}
}
.menu-trigger{
	display: none;
}

nav li{
	display:inline-block;
	padding:4px 0 4px 20px;
	&::after{
		content: '';
		width: 0;
		height: 24px;
		border-radius: 2px;
		border: 2px solid $main_color;
		display: inline-block;
		vertical-align: middle;
		margin-left: 20px;
	}
}
nav li:last-child{
	&::after{
		content: none;
	}
}
nav li a{
	color:#ee7800;
	font-size:15.5px;
	font-weight: bold;
	display: inline-block;
	vertical-align: middle;
}
nav li a:hover{color:#f8c799;}
#nav_toggle{display: none;}
.navi4{display: none;}
.navi5{display: none;}
.navi6{display: none;}
.logo{display: none;}


/* **************************
 　　メインイメージ
************************** */

#main01{
	padding:0;
	width: 100%;
	background: url(../img/image01-1.jpg) center center / cover no-repeat;
	-webkit-animation: fadeIn 8s ease 0s 1 normal;
	animation: fadeIn 8s ease 0s 1 normal;
	display:table;
}
#main01-s{display: none;}
#main01-p{display: none;}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.box{
	display:table-cell;
	vertical-align:middle;
}
.m-logo{
	width:458px;
	margin:0 auto;
	animation: fadeIn 8s ease 0s 1 normal;
	-webkit-animation: fadeIn 8s ease 0s 1 normal;
}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.m-text{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	padding:25px 0 0 0;
	color:#fff;
	font-size:17px;
	text-align:center;
	line-height: 1.8em;
	letter-spacing: 0.2em;
}

/* **************************
 　　ご挨拶
************************** */

.greet{
	font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
	width:830px;
	margin:70px auto;
}
.greet h3{
	margin:0 0 20px 0;
	padding:0 0 20px 0;
	font-size:32px;
	line-height: 1.5em;
	font-weight: bold;
	text-align:center;
	border-bottom:1px solid #000;
}
.greet p{
	font-size:19px;
	line-height: 1.8em;
}


/* **************************
 　　内覧会のご案内
************************** */

#guide-h{
	width:100%;
	background:#f1ede2;
	padding:70px 0;
}
#guide{
	width:1030px;
	margin:0 auto;
	padding: 0;
}
.tb{display:none;}
.sp{display:none;}
#guide h4{
	padding-bottom: 30px;
	font-size:30px;
	line-height: 1.5em;
	color:#ee7800;
	border-bottom:1px solid #fff;
	text-align:center;
}
#guide h4:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:5px;
	position: relative;
	top: -5px;
	left:0;
}
.g-group01{width:600px;float:left;}
#g01{
	padding:20px;
	font-size:15px;
	line-height: 1.8em;
	padding-bottom:10px;

}
#g02{
	height:260px;
	padding-bottom:4px;
	position: relative;
}
#g02 .icon02{
	display:block;
	width:112px;
	height:121px;
	position: absolute;
	top:0;
	left:0;
	z-index:1;
}
#g02 .icon02 img{width:100%;}
#g02 .con01{
	display:inline-block;
	width:425px;
	height:180px;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	position: absolute;
	top:47px;
	left:75px;
	padding:18px 18px 18px 50px;
	font-size:15px;
	line-height: 1.8em;
}
.con01 h5{
	font-size:17px;
	color:#ee7800;
	border-bottom:1px solid #ee7800;
	width:100%;
	padding-bottom:7px;
	margin-bottom:10px;
}
.con01 table{
	width:400px;

}
.con01 th{width:120px;}
.con01 th,td{
	padding:5px 5px;
	text-align:left;
	word-break : break-all;
	font-size:15px;
	line-height: 1.8em;

}
#g03{
	height:220px;
	position: relative;
}
#g03 .icon02{
	display:inline-block;
	width:113px;
	height:120px;
	position: absolute;
	top:0;
	left:0;
	z-index:1;
}
#g03 .icon02 img{width:100%;}
#g03 .con01{
	display:inline-block;
	width:425px;
	height:120px;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	position: absolute;
	top:47px;
	left:75px;
	padding:18px 18px 18px 50px;
	font-size:15px;
	line-height: 1.8em;
}

#g04{margin:0;padding:22px 0 40px 0;}
#g04 img{width:430px;}
#g05{
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	padding:36px 15px 36px 15px;
	text-align:center;
	color:#fff;
	line-height: 150%;
	font-size:18px;
}
#g05 p{
	margin-bottom:16px;
	border-bottom:1px solid #fff;
	padding-bottom: 10px;
}

.g-group02{width:430px;float:left;}
.g-group03{display: none;}
.g-group04{display: none;}
.g-group05{display: none;}
.g-group06{display: none;}
.g-group07{display:none;}
.g-group08{display: none;}
.flex{
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.map h5{
	font-size:20px;
	font-weight: bold;
	padding-bottom:12px;
}

.center{text-align:center;}


/* **************************
 　　施設概要
************************** */

.facility {padding:70px 0 0 0;}
.facility h5{font-size:20px;padding:0 0 10px 0;}
.summary{
	width:830px;
	margin:20px auto 20px auto;
	padding:35px 0 20px 0;
	border-top:1px solid #999999;
}
.summary tr{border-bottom:1px solid #999999;}
.summary th{
	padding:28px 30px;
	font-weight: normal;
	font-size:15px;
	line-height: 1.8em;
	text-align:left;
}
.summary td{
	padding:28px 30px;
	font-weight: normal;
	font-size:15px;
	line-height: 1.8em;
	text-align:left;
}
.map {
	width:830px;
	height:560px;
	margin:55px auto;
}
.map iframe{height:560px;}


/* **************************
 　　法人概要
************************** */

#corpo,
#work{
	padding:70px 0;
	h3{
		padding: 18px 0 10px 0;
		font-size:30px;
		line-height: 1.5em;
		font-weight: bold;
		color:#ee7800;
		text-align:center;
		&::before{
			content:" ";
			display:inline-block;
			width:43px;
			height:52px;
			background:url(../img/icon01.png);
			background-size:contain;
			vertical-align:middle;
			margin-right:10px;
			position: relative;
			top: -5px;
			left:0;
		}
	}
}


/* **************************
 　　お問い合わせ
************************** */

#contact{
	width:100%;
	background:#f1ede2;
	text-align:center;
	margin:0;
	padding:70px 0 70px 0;
}
#contact h3{
	margin:0 0 20px 0;
	padding: 18px 0 18px 0;
	font-size:30px;
	line-height: 1.5em;
	color:#ee7800;
	font-weight: bold;
	text-align:center;
	border-bottom:1px solid $main_color;
}
#contact h3:before{
	content:" ";
	display:inline-block;
	width:43px;
	height:52px;
	background:url(../img/icon01.png);
	background-size:contain;
	vertical-align:middle;
	margin-right:10px;
	position: relative;
	top: -5px;
	left:0;
}
#contact p{
	margin:0;
	padding:0;
	line-height: 1.8em;
}
#contact .btn01{
	display: block;
	margin:50px 0 20px 0;
}
#contact .btn01 a{
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	padding:16px 30px;
	font-size:21px;
	line-height: 1.8em;
	font-weight: bold;
	color:#fff;
}
#contact .btn01 a:hover{
	background: #ffc578;
	background: -moz-linear-gradient(top, #ffc578 0%, #fb9d23 100%);
	background: -webkit-linear-gradient(top, #ffc578 0%,#fb9d23 100%);
	background: linear-gradient(to bottom, #ffc578 0%,#fb9d23 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffc578', endColorstr='#fb9d23',GradientType=0 );
}


/* **************************
 　　フッター
************************** */
#footer{
	display: block;
	width:100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	color:#fff;
	padding:75px 0;
	text-align:center;
}
#footer ul{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 450px;
	margin:30px auto;

}
#footer li{
	vertical-align: middle;
	list-style-type: none;
	margin:0;
	font-size:16px;
	a{
		color: #fff;
		&:hover{
			opacity: .4;
		}
	}
}
#footer img{width:185px;margin-bottom: 16px;}
#footer .copy{font-size:12px;}


/* **************************
 　　ページトップ
************************** */

#page-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index:20;
}
#page-top a {
	text-align: center;
	display: block;
}
#page-top a:hover img{ opacity: 0.6;}

#page-top img{width:90px;}

#page-top1 a {display: block;}
#page-top1 a:hover img{ opacity: 0.4;}

/* **************************
 　　SP固定ナビ
************************** */

#sp-fix{display:none;}

/* **************************
 　　js周り
************************** */

/* ------------- スクロールフェードインDown用 ------------- */
.fadeInDown {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:1s;
	-ms-animation-duration:1s;
	animation-duration:1s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); -ms-transform: translateY(20px); transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}

.fadeInDown1 {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:3s;
	-ms-animation-duration:3s;
	animation-duration:3s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); -ms-transform: translateY(30px); transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}
/* ------------- スクロールフェードインDown用 END ------------- */
